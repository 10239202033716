div.seatCharts-container {
  /*min-width: 700px;*/
}

div.seatCharts-cell {
  height: 16px;
  width: 16px;
  margin: 3px;
  float: left;
  text-align: center;
  outline: none;
  font-size: 13px;
  line-height: 16px;
  color: blue;
}

div.seatCharts-seat {
  background-color: green;
  color: white;
  border-radius: 5px;
  cursor: default;
  float: left;
}

div.seatCharts-seat:focus {
  border: none;
}

/*
.seatCharts-seat:focus {
	outline: none;
}
*/
div.seatCharts-space {
  background-color: white;
}

div.seatCharts-row {
  height: 50px;
}

div.seatCharts-row:after {
  clear: both;
}

div.seatCharts-seat.selected {
  background-color: aqua;
}

div.seatCharts-seat.focused {
  background-color: #6db131;
}

div.seatCharts-seat.available {
  background-color: green;
}

div.seatCharts-seat.unavailable {
  background-color: red;
  cursor: not-allowed;
}

div.seatCharts-seat.unavailable_f {
  background-color: red;
  cursor: not-allowed;
}

div.seatCharts-seat.unavailable_m {
  background-color: red;
  cursor: not-allowed;
}

div.seatCharts-seat.unavailable_l {
  background-color: red;
  cursor: not-allowed;
}

div.seatCharts-seat.unavailable_t {
  background-color: red;
  cursor: not-allowed;
}

ul.seatCharts-legendList {
  list-style: none;
}

li.seatCharts-legendItem {
  margin-top: 10px;
  line-height: 2;
}

@font-face {
  font-family: f5;
  src: url("/assets/tawseel_app/fonts/DroidKufi-Regular.ttf");
}
body {
  font-family: "f5";
  /* font-size: 18px; */
}